// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-articles-article-js": () => import("./../src/components/articles/article.js" /* webpackChunkName: "component---src-components-articles-article-js" */),
  "component---src-components-fundraiser-fundraiser-js": () => import("./../src/components/fundraiser/fundraiser.js" /* webpackChunkName: "component---src-components-fundraiser-fundraiser-js" */),
  "component---src-components-projects-project-js": () => import("./../src/components/projects/project.js" /* webpackChunkName: "component---src-components-projects-project-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-js": () => import("./../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cv-js": () => import("./../src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-fundraisers-js": () => import("./../src/pages/fundraisers.js" /* webpackChunkName: "component---src-pages-fundraisers-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

